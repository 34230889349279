import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Footer, Layout, Navbar, PageHeader, SponsorRow } from '@components';
import { graphql } from 'gatsby';
import '@scss/sponsors-page.scss';

const SponsorsListPage = ({ data }) => {
  const { sponsors } = data.postgres;

  return <Layout className="supporters-page">
    <Navbar />
    <PageHeader
      title="Sponsors"
      paddingBottom="60px"
      titleTransform="uppercase"
    />
    <div className="sponsor">
      <Container>
        <div className="supporters">
          <Row className="justify-content-center premier-sponsors">
            <SponsorRow cols={6} sponsors={sponsors.filter(sponsor => sponsor.level === `Premier Sponsor`)} />
          </Row>
          <Row className="justify-content-center">
            <SponsorRow sponsors={sponsors.filter(sponsor => sponsor.level === `Gold Sponsor`)} />
          </Row>
          <Row className="justify-content-center">
            <SponsorRow sponsors={sponsors.filter(sponsor => sponsor.level === `Silver Sponsor`)} />
          </Row>
          <Row className="justify-content-center">
            <SponsorRow sponsors={sponsors.filter(sponsor => sponsor.level === `Donor`)} />
          </Row>
          <Row className="justify-content-center">
            <SponsorRow sponsors={sponsors.filter(sponsor => sponsor.level === `Judging Provided By`)} />
          </Row>
        </div>
      </Container>
    </div>
    <Footer />
  </Layout>;
};

export const query = graphql`
  query SponsorPageQuery ($year: String){
    postgres {
      sponsors: allSponsorsList(condition: { year:$year }) {
        hasSponsorRoom
        website
        id
        logo
        logoFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        level
        name
        year
      }
    }
  }
`;

export default SponsorsListPage;
